import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import ImageAWS from "../components/image-aws"
import ImageWP from "../components/image-wp"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About Nathan Wallace" />
    <h1>Hi, I'm Nathan</h1>
    <section>
      <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
        <Image />
      </div>
      <h2>About</h2>
      <p>Wallace Creative has been providing web-consulting services to higher-ed, e-commerce, and government clients since 2015.</p>
    </section>
    <section>
      <h2>Technologies</h2>
      <p>Wallace Creative specializes in the implementation of open-source software solutions and cloud technologies for customers across a variety of industries.</p>
      <div style={{ WebkitColumnCount: "2", MozColumnCount: "2", columnCount: "2"}}>
      <ImageAWS />
      <ImageWP />
      </div>
    </section>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default AboutPage
